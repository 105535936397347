import * as THREE from "three";
import Stats from "three/examples/jsm/libs/stats.module";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js";

let scene;
let camera;
let renderer;
let controls;
let container;
let cube;
let plane1;
let plane2;
let stats;
let texs = [];
let planes = [];
let videos = [];
let iSize = 9;
let gui;
let phiOffsets = [];

let state = {
  iSize: 9,
  radius: 3.0,
  N: 25,
  modParameter: 5.0,

  offset: 0.0,

  randomize: true,

  videoPause: () => {
    videos.forEach((video) => {
      video.pause();
    });
  },
  videoPlay: () => {
    videos.forEach((video) => {
      video.play();
    });
  },
};

let size = 1.0;
let maxISize = 60;
let ub = 270;
let offsetUpdate = false;

let planeVideoMap = {};
let videoPlaneMap = {};

let videosToDelete = [];

let videoNames = [];
let preVideoNames = [
  "2morrow-1.mp4",
  "314-1.mp4",
  "4ipsa-1.mp4",
  "7egend-1.mp4",
  "9elements-1.mp4",
  "9elements-2.mp4",
  "9elements-3.mp4",
  "9elements-4.mp4",
  "access-is-1.mp4",
  "advv-1.mp4",
  "advv-2.mp4",
  "aero-1.mp4",
  "ailove-1.mp4",
  "ailove-2.mp4",
  "als-junior.mp4",
  "amp-1.mp4",
  "amp-2.mp4",
  "amp-3.mp4",
  "anjelika-petrova-1.mp4",
  "anjelika-petrova-2.mp4",
  "anjelika-petrova-3.mp4",
  "anjelika-petrova-4.mp4",
  "anjelika-petrova-5.mp4",
  "anjelika-petrova-6.mp4",
  "anjelika-petrova-7.mp4",
  "apple-card.mp4",
  "apple-card-2.mp4",
  "apple-card-3.mp4",
  "april-1.mp4",
  "april-2.mp4",
  "avellum-1.mp4",
  "barba-1.mp4",
  "barba-2.mp4",
  "barba-3.mp4",
  "barba-4.mp4",
  "barba-5.mp4",
  "bepatrickdavid-1.mp4",
  "bose-1.mp4",
  "bose-2.mp4",
  "buroburo-1.mp4",
  "capsula-1.mp4",
  "capsula-2.mp4",
  "capsula-3.mp4",
  "cher-ami-1.mp4",
  "chipsa-1.mp4",
  "cookii-1.mp4",
  "cookii-2.mp4",
  "cookii-3.mp4",
  "cookii-4.mp4",
  "cookii-5.mp4",
  "cr7-1.mp4",
  "cr7-2.mp4",
  "cr7-3.mp4",
  "cr7-4.mp4",
  "cristi-1.mp4",
  "cristi-2.mp4",
  "criticalsoftware-1.mp4",
  "criticalsoftware-2.mp4",
  "criticalsoftware-3.mp4",
  "criticalsoftware-4.mp4",
  "criticaltech-1.mp4",
  "criticaltech-2.mp4",
  "criticaltech-3.mp4",
  "csssr-1.mp4",
  "cursomizer-1.mp4",
  "curtains-1.mp4",
  "curtains-2.mp4",
  "curtains-3.mp4",
  "dada-1.mp4",
  "dbtc-1.mp4",
  "delt-1.mp4",
  "deluxe-1.mp4",
  "digitalconference-1.mp4",
  "digitalconference-2.mp4",
  "digital-cover-1.mp4",
  "dyson-1.mp4",
  "dyson-2.mp4",
  "ecwid.mp4",
  "ecwid-2.mp4",
  "electroneek-1.mp4",
  "emerge.mp4",
  "fact-1.mp4",
  "federationtower.mp4",
  "fishlab-1.mp4",
  "flama-1.mp4",
  "flama-2.mp4",
  "friends-1.mp4",
  "frontmen-1.mp4",
  "frontmen-2.mp4",
  "frontmen-3.mp4",
  "github-1.mp4",
  "hellomrfrank-1.mp4",
  "hot-walls-1.mp4",
  "hover-1.mp4",
  "huawei.mp4",
  "huawei-2.mp4",
  "huawei-3.mp4",
  "huawei-4.mp4",
  "huawei-5.mp4",
  "huawei-6.mp4",
  "huawei-7.mp4",
  "huawei-8.mp4",
  "hungry-1.mp4",
  "hungry-2.mp4",
  "i-bank.mp4",
  "idem-1.mp4",
  "idem-2.mp4",
  "ingos-1.mp4",
  "internet-design-1.mp4",
  "investore.mp4",
  "iphone-11-pro.mp4",
  "iphone-11-pro-2.mp4",
  "iphone-11-pro-3.mp4",
  "iphone-11-pro-4.mp4",
  "iphone-11-pro-5.mp4",
  "iphone-11-pro-6.mp4",
  "iphone-11-pro-7.mp4",
  "isobar-1.mp4",
  "isobar-2.mp4",
  "jami-1.mp4",
  "jami-2.mp4",
  "jami-3.mp4",
  "jetbrains-1.mp4",
  "justin-1.mp4",
  "krik-1.mp4",
  "krik-2.mp4",
  "krik-3.mp4",
  "laguta-1.mp4",
  "laguta-2.mp4",
  "lamborghini-1.mp4",
  "lavva-1.mp4",
  "lobster-1.mp4",
  "looi-1.mp4",
  "looi-2.mp4",
  "loud-1.mp4",
  "maketheweb-1.mp4",
  "marketgroup-1.mp4",
  "martin-1.mp4",
  "mediadirection-1.mp4",
  "medolubov.mp4",
  "medolubov-2.mp4",
  "medolubov-3.mp4",
  "medolubov-4.mp4",
  "mfk-1.mp4",
  "miranda-1.mp4",
  "miranda-2.mp4",
  "miranda-3.mp4",
  "miranda-4.mp4",
  "miranda-5.mp4",
  "moeda-1.mp4",
  "moeda-2.mp4",
  "mts-1.mp4",
  "mwi-1.mp4",
  "nectarin-1.mp4",
  "nectarin-2.mp4",
  "oberhaeuser-1.mp4",
  "ootb-1.mp4",
  "osom-1.mp4",
  "perushev-1.mp4",
  "perushev-2.mp4",
  "pikabu-1.mp4",
  "pixelsnap-1.mp4",
  "pixelsnap-2.mp4",
  "possible-1.mp4",
  "proscom-1.mp4",
  "proscom-2.mp4",
  "proscom-3.mp4",
  "psbank-1.mp4",
  "raiffeisen-1.mp4",
  "raiffeisen-2.mp4",
  "raleigh-1.mp4",
  "raleigh-2.mp4",
  "readymag-1.mp4",
  "redcollar-1.mp4",
  "redcollar-2.mp4",
  "redcollar-3.mp4",
  "redcollar-4.mp4",
  "redcollar-5.mp4",
  "redcollar-6.mp4",
  "redcollar-7.mp4",
  "redcollar-8.mp4",
  "retool-1.mp4",
  "retool-2.mp4",
  "s-10.mp4",
  "s-10-2.mp4",
  "s8-capital.mp4",
  "salt-1.mp4",
  "sarah-1.mp4",
  "scrollmagic.mp4",
  "scrollmagic-2.mp4",
  "scrollmagic-3.mp4",
  "scrollmagic-4.mp4",
  "scrollmagic-5.mp4",
  "scrollmagic-6.mp4",
  "scrollmagic-7.mp4",
  "sibirix-1.mp4",
  "sibur-1.mp4",
  "siggi-1.mp4",
  "singularity-app-1.mp4",
  "sip.mp4",
  "sip-2.mp4",
  "soletanche-1.mp4",
  "soletanche-2.mp4",
  "taventures-1.mp4",
  "taventures-2.mp4",
  "techart-1.mp4",
  "tiltjs.mp4",
  "trionn-1.mp4",
  "trionn-2.mp4",
  "tylerstober-1.mp4",
  "uplab-1.mp4",
  "uplab-2.mp4",
  "vestfrost-1.mp4",
  "vestfrost-2.mp4",
  "vexor-1.mp4",
  "vfr-1.mp4",
  "vintage-1.mp4",
  "vintage-2.mp4",
  "vintage-3.mp4",
  "visualsoldiers-1.mp4",
  "voskhod-1.mp4",
  "vue-1.mp4",
  "vue-2.mp4",
  "vultr-1.mp4",
  "vultr-2.mp4",
  "vultr-3.mp4",
  "vultr-4.mp4",
  "wayray-1.mp4",
  "wayray-2.mp4",
  "wayray-3.mp4",
  "wayray-4.mp4",
  "wayray-5.mp4",
  "wezom-1.mp4",
  "wezom-2.mp4",
  "wezom-3.mp4",
  "witnessco-1.mp4",
  "yaay-1.mp4",
  "zeusceramica-1.mp4",
  "zeusceramica-2.mp4",
  "zeusceramica-3.mp4",
];

let timer;

for (let t = 0; t < maxISize; t++)
  phiOffsets.push(t % 2 == 0 ? 0.0 : 2.0 * Math.PI * state.offset);

function setup() {
  container = document.getElementById("container");
  resetGlobals();
  setupScene();
  setupRenderer();
  setupEventListeners();
  setupCamera();
  setupLights();

  stats = Stats();
  gui = new GUI();

  gui.add(state, "radius", 0.5, 5).listen();
  gui
    .add(state, "iSize", 3, maxISize, 1)
    .listen()
    .onChange(() => {
      videoPlaneMap = {};
      planes = [];
      setupScene();
      buildScene();
    });

  gui
    .add(state, "N", 1, 240, 1)
    .listen()
    .onChange(() => {
      //videosToDelete = videos;
      // videosToDelete.forEach((video) => {
      //   video.remove();
      // });
      if (timer) clearTimeout(timer);

      timer = setTimeout(() => {
        videos.forEach((video, i) => {
          video.addEventListener("playing", () => {
            video.pause();
            video.removeAttribute("src");
            video.load();

            video.remove();
          });

          setTimeout(() => {
            if (video) {
              //video.pause();
              //video.pause();
              video.removeAttribute("src");
              video.load();

              video.remove();
            }
          }, 500);

          delete videos[i];
        });

        resetGlobals();
        initializeVideos();
        setupScene();
        buildScene();
      }, 500);
    });

  gui.add(state, "modParameter", 1.0, 40.0, 1.0).listen();
  gui
    .add(state, "offset", 0, 1)
    .listen()
    .onChange((v) => {
      offsetUpdate = true;
      //planes.forEach(plane => { plane.lookAt(new THREE.Vector3(0, 0, 1)); });
    });
  gui.add(state, "randomize", true, false).listen();
  gui.add(state, "videoPause");
  gui.add(state, "videoPlay");

  container.appendChild(stats.domElement);
  container.appendChild(gui.domElement);
  controls = new OrbitControls(camera, renderer.domElement);

  initializeVideos();
  buildScene();
  // setTimeout(() => {
  //   buildScene();
  // }, 4000);
  draw();
}

function buildScene() {
  let iSize = state.iSize;
  size = (2.0 * Math.PI) / iSize;
  // phiOffsets = [];
  // planes = [];

  let k = 0;
  for (let i = 0; i <= iSize - 1; i++) {
    for (let j = 0; j <= iSize - 1; j++) {
      let phi = (phiOffsets[j] + i * size) % (2.0 * Math.PI);
      let theta = (size / 4.0 + (j * size) / 2.0) % Math.PI;

      let rad = state.radius;

      let h = rad * Math.sin(theta);

      let plane = new THREE.Mesh(
        new THREE.PlaneGeometry(0.85 * h * size, (0.85 * h * size) / 2),
        new THREE.MeshBasicMaterial({
          map: texs[k % state.N],
          side: THREE.FrontSide,
        }),
      );

      plane.position.x = rad * Math.sin(theta) * Math.cos(phi); //i * size;
      plane.position.y = rad * Math.cos(theta); //j * size;
      plane.position.z = 1 + rad * Math.sin(theta) * Math.sin(phi);

      //plane.lookAt(camera.position);
      plane.lookAt(new THREE.Vector3(0, 0, 1));

      plane.frustumCulled = true;

      planes.push(plane);
      scene.add(plane);

      if (videoPlaneMap[videoNames[k % state.N]])
        videoPlaneMap[videoNames[k % state.N]].push(plane);
      else videoPlaneMap[videoNames[k % state.N]] = [plane];

      k++;
    }
  }

  texs.forEach((tex, i) => {
    tex.needsUpdate = true;
  });
}

function updateScene() {
  let k = 0;
  let iSize = state.iSize;

  if (offsetUpdate) {
    for (let t = 0; t < maxISize; t++)
      if (t % 2 != 0) phiOffsets[t] = 2.0 * Math.PI * state.offset;
  }

  for (let i = 0; i <= iSize - 1; i++) {
    for (let j = 0; j <= iSize - 1; j++) {
      let plane = planes[k];
      let phi = (phiOffsets[j] + i * size) % (2.0 * Math.PI);
      let theta = (size / 4.0 + (j * size) / 2.0) % Math.PI;

      let rad = state.radius;

      let h = rad * Math.sin(theta);

      // plane.scale.x = (0.85 * h * size) / plane.geometry.parameters.width;
      // plane.scale.y = (0.85 * h * size) / plane.geometry.parameters.width;
      // plane.scale.z = (0.85 * h * size) / plane.geometry.parameters.width;

      plane.position.x = rad * Math.sin(theta) * Math.cos(phi); //i * size;
      plane.position.y = rad * Math.cos(theta); //j * size;
      plane.position.z = 1 + rad * Math.sin(theta) * Math.sin(phi);

      if (offsetUpdate) plane.lookAt(new THREE.Vector3(0, 0, 1));

      k++;
    }
  }

  if (offsetUpdate) offsetUpdate = false;
}

function resetGlobals() {
  planes = [];

  videoNames = [];
  texs = [];
  videoPlaneMap = {};
}

function initializeVideos() {
  // videoNames = [];
  // texs = [];
  // videoPlaneMap = {};

  for (let j = 0; j < state.N; j++) videoNames.push(preVideoNames[j]);

  //planeVideoMap = videoNames.map((_, i) => i);
  videos = videoNames.map((name, i) => setUpVideo("video/" + name, i));
  texs = videos.map((video) => createTextureFromVideoElement(video));
}

function setupScene() {
  scene = new THREE.Scene();
}

function setupCamera() {
  let res = window.innerWidth / window.innerHeight;
  camera = new THREE.PerspectiveCamera(75, res, 0.1, 1000);
  camera.position.z = 1;
  camera.position.y = 0;
  camera.position.x = 0;
}

// https://stackoverflow.com/questions/19251983/dynamically-create-a-html5-video-element-without-it-being-shown-in-the-page/20611625

function setUpVideo(inSrc, i) {
  var videlem = document.createElement("video");
  /// ... some setup like poster image, size, position etc. goes here...
  /// now, add sources:
  var sourceMP4 = document.createElement("source");
  sourceMP4.type = "video/mp4";
  sourceMP4.src = inSrc;
  // performance

  videlem.appendChild(sourceMP4);

  videlem.autoplay = true;
  videlem.preload = "auto";
  videlem.muted = true;
  //videlem.loop = true;
  videlem.width = 50;
  videlem.height = 50;
  //videlem.setAttribute("crossorigin", "anonymous");
  // i think this will not be not be needed if you have a server

  videlem.style.display = "none"; // this makes it so the html element isnt there

  videlem.load();
  videlem.play();

  videlem.addEventListener("ended", () => {
    let vN = inSrc.slice(6);
    if (state.randomize) {
      if (videoNames.indexOf(vN) != -1 && videoPlaneMap[vN]) {
        let N = videoPlaneMap[vN].length;

        for (let i = 0; i < N; i++) {
          let plane = videoPlaneMap[vN].shift();
          let index = Math.floor(state.N * Math.random());
          let name = videoNames[index];
          plane.material.map = texs[index];
          if (videoPlaneMap[name]) videoPlaneMap[name].push(plane);
        }
      }
    }

    if (videoNames.indexOf(vN) != -1) videlem.play();
  });

  return videlem;
}

function createTextureFromVideoElement(video) {
  let texture = new THREE.Texture(video);

  texture.minFilter = THREE.LinearFilter;
  texture.magFilter = THREE.LinearFilter;
  texture.format = THREE.RGBAFormat;
  return texture;
}

function setupRenderer() {
  renderer = new THREE.WebGL1Renderer({ antialias: true });
  renderer.setSize(window.innerWidth, window.innerHeight);
  //document.body.appendChild(renderer.domElement);
  container.appendChild(renderer.domElement);
}

function setupCube(texture) {
  let geometry = new THREE.BoxGeometry(1, 1, 1);
  let material = new THREE.MeshPhongMaterial({
    map: texture,
    shininess: 1,
    side: THREE.DoubleSide,
  });
  cube = new THREE.Mesh(geometry, material);
  scene.add(cube);
}

function setupLights() {
  let ambientLight = new THREE.AmbientLight(0x0c0c0c);
  scene.add(ambientLight);

  let spotLight = new THREE.SpotLight(0xffffff);
  spotLight.position.set(-30, 60, 60);
  spotLight.castShadow = true;
  scene.add(spotLight);
}

function setupEventListeners() {
  window.addEventListener("resize", onWindowResize);
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

let r = 0;

function draw() {
  requestAnimationFrame(draw);
  stats.begin();

  //setTimeout(() => {texs[0].needsUpdate = true}, 3000);

  //planes.forEach(plane => { plane.lookAt(camera.position) });
  controls.update();
  updateScene();
  texs.forEach((tex, i) => {
    if (tex && i % state.modParameter == r) tex.needsUpdate = true;
  });

  r = (r + 1) % state.modParameter;

  renderer.render(scene, camera);

  let angle = 0.007;
  //plane1.rotation.x += angle;
  //plane1.rotation.y += angle * 0.125;

  //plane2.rotation.x -= angle;
  //plane2.rotation.y -= angle * 0.99;

  // camera.position.x += 0.01;
  stats.end();
}

setup();
